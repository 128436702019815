import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [~6,[2]],
		"/(app)/404": [~7,[2]],
		"/(app)/about": [~10,[2]],
		"/(app)/b2b": [~11,[2]],
		"/(app)/b2b/branding": [~13,[2]],
		"/(app)/b2b/finance": [~14,[2]],
		"/(app)/b2b/job-finding": [~15,[2]],
		"/(app)/b2b/offerta/b2b-formazione-su-misura": [~17,[2]],
		"/(app)/b2b/offerta/b2b-hr-for-breakfast": [~18,[2]],
		"/(app)/b2b/offerta/b2b-le-masterclass": [~19,[2]],
		"/(app)/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende": [~20,[2]],
		"/(app)/b2b/offerta/[slug]/brochure_download_thankyou": [~16,[2]],
		"/(app)/b2b/opportunita/b2b-fondo-nuove-competenze": [~22,[2]],
		"/(app)/b2b/opportunita/[slug]/brochure_download_thankyou": [~21,[2]],
		"/(app)/b2b/skilling": [~23,[2]],
		"/(app)/b2b/thankyou": [24,[2]],
		"/(app)/b2b/[type]/[slug]/contact": [~12,[2]],
		"/(app)/certificazioni-ISO": [~25,[2]],
		"/(app)/contact-us": [~26,[2]],
		"/(app)/cookie-policy": [~27,[2]],
		"/(app)/diritto-di-recesso": [~28,[2]],
		"/(app)/docenti": [~29,[2]],
		"/(app)/docenti/[slug]": [~30,[2]],
		"/(app)/edu/[slug]": [~31,[2]],
		"/(app)/esplora": [~32,[2]],
		"/(app)/eventi": [~33,[2]],
		"/(app)/eventi/[slug]": [~34,[2]],
		"/(app)/eventi/[slug]/booking_confirm": [~35,[2]],
		"/(app)/eventi/[slug]/richiedi_informazioni": [~36,[2]],
		"/(app)/exception": [37,[2]],
		"/(app)/faq": [~38,[2]],
		"/(app)/finanziamenti": [~39,[2]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/application_form": [~41,[2]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/application_laurea_result": [~42,[2]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/application_result": [~43,[2]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/buy/options": [~44,[4]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/buy/packages": [~45,[4]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/download_brochure": [~46,[2]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/request_information_form": [~47,[2]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[idSession]/request_information_result": [~48,[2]],
		"/(app)/formazione/[courseTypeSlug]/[courseSlug]/[[idSession]]": [~40,[2,3]],
		"/(app)/inforisk": [~49,[2]],
		"/(app)/login": [50,[2]],
		"/(app)/login/activate": [51,[2]],
		"/(app)/login/callback_social": [53,[2]],
		"/(app)/login/callback": [52,[2]],
		"/(app)/lp/[slug]": [~54,[2]],
		"/(app)/mappa-del-sito": [~55,[2]],
		"/(app)/mba": [~56,[2]],
		"/(app)/my-data": [57,[2]],
		"/(app)/my-privacy": [58,[2]],
		"/(app)/news": [~59,[2]],
		"/(app)/news/[slug]": [~60,[2]],
		"/(app)/orientamento": [~61,[2]],
		"/(app)/orientamento/highlevel-thankyou": [63,[2]],
		"/(app)/orientamento/highlevel": [~62,[2]],
		"/(app)/orientamento/thankyou": [64,[2]],
		"/(app)/partners": [~65,[2]],
		"/(app)/partners/[slug]": [~66,[2]],
		"/(app)/privacy-policy": [~67,[2]],
		"/(app)/registrati": [68,[2,5]],
		"/(app)/registrati/completion": [69,[2,5]],
		"/(app)/registrati/form": [70,[2,5]],
		"/(app)/sedi": [~71,[2]],
		"/(app)/trasparenza": [~72,[2]],
		"/(app)/whistleblowing": [~73,[2]],
		"/(app)/[listingCourseTypeSlug=listingCourseTypeSlug]/[[page=pageNumber]]": [~9,[2]],
		"/(app)/[listingCourseCategorySlug=listingCourseCategorySlug]/[[page=pageNumber]]": [~8,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';